/* js scripts */

$('a.gotoAbout').click(function(e) {
  e.preventDefault();
  var destId = $(this).data('about');
  var idToGo = '#about_' + destId;
  $(this).parents('div.aboutSection').fadeOut(300, function() {
    $(idToGo).fadeIn(300);
  });
});

$('a.detailsNav').click(function(e) {
  e.preventDefault();
  $('a.detailsNav').removeClass('active');
  $(this).addClass('active');
  var detId = $(this).data('detail');
  var detToShow = '#detail_' + detId;
  $('div.aboutDetail:visible').fadeOut(300, function() {
    $(detToShow).fadeIn(300);
  });
});

$('#send').click(function(e) {
  if (valid($('#contactForm'))) {
    var fd = {};
    fd.nome = $('#nome').val();
    fd.cognome = $('#cognome').val();
    fd.mail = $('#email').val();
    fd.body = $('#body').val();

    $.ajax({
      method: 'POST',
      url: '/ajax/mail.php',
      data: fd,
      success: function(d) {
        if (d == 1) {
          $('#formContainer').slideUp();
          $('#formFeedback').slideDown();
        }
      } //success
    }); //ajax
  } //if
});

function valid(f) {
  f.find('.required').each(function() {
    if ($(this).val() == '') {
      $(this).addClass('error');
    } else {
      $(this).removeClass('error');
    }
  });
  if ($('.error').length !== 0) {
    return false;
  } else {
    return true;
  }
}
